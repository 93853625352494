<template>
  <div v-loading="loading">
    <div class="package_title">
      <div style=" width: 93%;">
        <el-menu
          :default-active="defaultActive"
          class="el-menu-package"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item
            :index="String(group.id)"
            v-for="group in packageGroupList"
            :key="group.id"
          >{{group.packageName}}</el-menu-item>
        </el-menu>
      </div>
      <div class="package_btn">
        <el-button type="primary" size="mini" @click="dialogpackageGroup=true">分类管理</el-button>
      </div>
    </div>

    <el-card shadow="never" style="margin-top:10px">
      <div slot="header" class="iconfont icon-Package">&nbsp;套餐信息</div>
      <el-row :gutter="10">
        <el-col
          :xl="4"
          :lg="6"
          :md="8"
          :sm="10"
          :xs="24"
          :offset="0"
          v-for="item in packageList"
          :key="item.id"
        >
          <div class="package_card">
            <div class="package_view">
              <div class="package_discount">{{item.packageMark}}</div>
              <div class="package_top">
                <span>{{item.packageName}}</span>
              </div>
              <div class="package_middle">
                <span class="package_middle_one">￥</span>
                <span class="package_middle_two">{{item.packagePrice}}</span>
                <span class="package_middle_three">￥{{item.packageOriginalPrice}}</span>
              </div>
              <div class="package_bottom">
                <el-button type="text" size="small" @click="openEditPackage(item)">编辑</el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" size="small" @click="onSubmitDel(item)">删除</el-button>
              </div>
            </div>
          </div>
        </el-col>

        <el-col :xl="4" :lg="6" :md="8" :sm="10" :xs="24" :offset="0">
          <div class="package_card" @click="openAddPackage">
            <div class="package_view">
              <div class="package_add_view">
                <i class="el-icon-plus package_add_icon"></i>
              </div>
              <div class="package_bottom" style="background:#efefef">
                <el-button type="text" size="small" style="color:#8c939d">添加套餐</el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <!-- 套餐组管理 -->
    <el-dialog :visible.sync="dialogpackageGroup" :close-on-click-modal="false" width="35%">
      <span slot="title">分类管理</span>
      <div style="padding:10px 0 20px 0"> 
        <div class="card_header_flex">
        <el-button type="primary" size="small" @click="dialogAddPackageGroup=true">新增套餐分类</el-button>
      </div>
      <el-table :data="packageGroupList" border size="mini" style="margin-bottom:10px">
        <el-table-column label="名称" prop="packageName">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-if="scope.row.isSelected"
              v-model="scope.row.packageName"
              ref="editInput"
              @focus="focusEvent(scope.row)"
              @blur="blurEvent(scope.row)"
              v-focus
            ></el-input>
            <span v-else>
              {{ scope.row.packageName }}
              <i
                class="el-icon-edit-outline"
                style="color:#5e72f9;font-size:15px;margin-left:10px;cursor:pointer;vertical-align:middle"
                @click="cellClick(scope.row)"
              ></i>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="delPackageGroup(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      </div>
    </el-dialog>

    <!-- 新增套餐分类 -->
    <el-dialog :visible.sync="dialogAddPackageGroup" :close-on-click-modal="false" width="25%">
      <span slot="title">新增套餐分类</span>
      <el-form
        label-width="50px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
        @submit.native.prevent
      >
        <el-form-item label="名称">
          <el-input v-model="packageGroupName" placeholder="请输入分类名称" maxlength="11" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddPackageGroup=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddPackageGroup">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增套餐-->
    <el-dialog
      :visible.sync="dialogAddPackage"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogAddPackage"
    >
      <span slot="title">新增套餐</span>
      <el-form
        :model="form"
        ref="form"
        label-width="120px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="套餐名称"
          prop="packageName"
          :rules="{ required: true, message: '请输入套餐名称', trigger: 'blur' }"
        >
          <el-input v-model="form.packageName" placeholder="请输入套餐名称" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item
          label="原价"
          prop="packageOriginalPrice"
          :rules="[{ required: true, message: '请输入套餐原价', trigger: 'blur' },{ pattern:/(^[1-9][0-9]{0,7}$)|(^((0\.0[1-9]$)|(^0\.[1-9]\d?)$)|(^[1-9][0-9]{0,7}\.\d{1,2})$)/,message: '请输入正确的价格格式',trigger: 'blur'}]"
        >
          <el-input v-model="form.packageOriginalPrice" placeholder="请输入套餐原价"></el-input>
        </el-form-item>
        <el-form-item
          label="当前价格"
          prop="packagePrice"
          :rules="[{ required: true, message: '请输入套餐当前价格', trigger: 'blur' },{ pattern:/(^[1-9][0-9]{0,7}$)|(^((0\.0[1-9]$)|(^0\.[1-9]\d?)$)|(^[1-9][0-9]{0,7}\.\d{1,2})$)/,message: '请输入正确的价格格式',trigger: 'blur'}]"
        >
          <el-input v-model="form.packagePrice" placeholder="请输入套餐当前价格"></el-input>
        </el-form-item>
        <el-form-item
          label="续费时长"
          prop="packageTime"
          :rules="{ required: true, message: '请选择续费时长', trigger: 'change' }"
        >
          <el-select v-model="form.packageTime" placeholder="请选择续费时长" style="width:100%">
            <el-option
              v-for="item in optionsTime"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="角标文字"
          prop="packageMark"

        >
          <el-input v-model="form.packageMark" placeholder="请输入角标文字" maxlength="10" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="说明" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入说明" type="textarea"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddPackage=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddPackage">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改套餐-->
    <el-dialog
      :visible.sync="dialogEditPackage"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogEditPackage"
    >
      <span slot="title">编辑套餐</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="120px"
        size="small"
        style="padding:20px"
        :hide-required-asterisk="true"
      >
        <el-form-item label="套餐名称" :rules="{ required: true, message: '请输入套餐名称', trigger: 'blur' }">
          <el-input v-model="editForm.packageName" placeholder="请输入套餐名称" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item
          label="原价"
          :rules="[{ required: true, message: '请输入套餐原价', trigger: 'blur' },{ pattern:/(^[1-9][0-9]{0,7}$)|(^((0\.0[1-9]$)|(^0\.[1-9]\d?)$)|(^[1-9][0-9]{0,7}\.\d{1,2})$)/,message: '请输入正确的价格格式',trigger: 'blur'}]"
        >
          <el-input v-model="editForm.packageOriginalPrice" placeholder="请输入套餐原价"></el-input>
        </el-form-item>
        <el-form-item
          label="当前价格"
          :rules="[{ required: true, message: '请输入套餐当前价格', trigger: 'blur' },{ pattern:/(^[1-9][0-9]{0,7}$)|(^((0\.0[1-9]$)|(^0\.[1-9]\d?)$)|(^[1-9][0-9]{0,7}\.\d{1,2})$)/,message: '请输入正确的价格格式',trigger: 'blur'}]"
        >
          <el-input v-model="editForm.packagePrice" placeholder="请输入套餐当前价格"></el-input>
        </el-form-item>
        <el-form-item
          label="续费时长"
          :rules="{ required: true, message: '请选择续费时长', trigger: 'change' }"
        >
          <el-select v-model="editForm.packageTime" placeholder="请选择" style="width:100%">
            <el-option
              v-for="item in optionsTime"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角标文字">
          <el-input v-model="editForm.packageMark" placeholder="请输入角标文字" maxlength="10" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="说明">
          <el-input v-model="editForm.remark" placeholder="请输入说明" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="启用状态">
          <el-radio-group v-model="editForm.state" size="mini">
            <el-radio-button label="on">启用</el-radio-button>
            <el-radio-button label="off">禁用</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditPackage=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditPackage">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPackageGroupApi,
  addPackageGroupApi,
  editPackageGroupApi,
  delPackageGroupApi,
  getPackageApi,
  addPackageApi,
  editPackageApi,
  delPackageApi
} from "../../api/data";
export default {
  data() {
    return {
      loading:true,
      defaultActive: "", //头部默认选中
      packageGroupList: [], //套餐分类集合
      packageList: [], //套餐列表集合
      dialogpackageGroup: false, //套餐分类修改窗口
      dialogAddPackageGroup: false, //套餐分类添加窗口
      dialogAddPackage: false, //新增套餐窗口
      dialogEditPackage: false, //修改套餐窗口
      packageGroup: {}, //
      form: {
        packageName: "",
        packageOriginalPrice: "",
        packageTime: "",
        packageId: "",
        packagePrice: "",
        packageMark: "",
        remark: ""
      },
      editForm: {},
      packageGroupName: "",
      optionsTime: [
        { value: 7, label: "七天", disabled: false },
        { value: 30, label: "一个月", disabled: false },
        { value: 183, label: "六个月", disabled: false },
        { value: 365, label: "一年", disabled: false },
        { value: 730, label: "二年", disabled: false },
        { value: 1095, label: "三年", disabled: false },
        { value: 1825, label: "五年", disabled: false }
      ]
    };
  },
  //自定义指令对文本框添加焦点
  directives: {
    focus: {
      inserted: function(el) {
        el.querySelector("input").focus();
      }
    }
  },
  created() {
    this.firstInitLoadPackageGroup();
  },
  methods: {
    //第一次加载套餐组列表
    firstInitLoadPackageGroup() {
      getPackageGroupApi().then(res => {
        this.loading=false;
        if (res) {
          //第一次加载时默认选择第一套餐组
          this.defaultActive = String(res.data[0].id);
          this.packageGroupList = res.data.map(item => {
            return { ...item, isSelected: false };
          });
          this.initLoadPackage(this.defaultActive);
        }
      });
    },
    //套餐组列表
    initLoadPackageGroup() {
      getPackageGroupApi().then(res => {
        if (res) {
          if(this.defaultActive==""){
            this.defaultActive = String(res.data[0].id);
          }
          this.packageGroupList = res.data.map(item => {
            return { ...item, isSelected: false };
          });
          this.initLoadPackage(this.defaultActive);
        }
      });
    },
    //套餐详情列表
    initLoadPackage(id) {
      getPackageApi({ id: id }).then(res => {
        this.packageList = res.data;
      });
    },
    //打开添加套餐窗口
    openAddPackage() {
      this.dialogAddPackage = true;
      var options = [
        { value: 7, label: "七天", disabled: false },
        { value: 30, label: "一个月", disabled: false },
        { value: 183, label: "六个月", disabled: false },
        { value: 365, label: "一年", disabled: false },
        { value: 730, label: "二年", disabled: false },
        { value: 1095, label: "三年", disabled: false },
        { value: 1825, label: "五年", disabled: false }
      ];
      //筛选下拉
      for (let i = 0; i < this.packageList.length; i++) {
        for (let j = 0; j < options.length; j++) {
          if (this.packageList[i].packageTime == options[j].value) {
            options[j].disabled = true;
          }
        }
      }
      this.optionsTime = options;
    },
    //添加套餐
    onSubmitAddPackage() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.packageId = this.defaultActive;
          addPackageApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.dialogAddPackage = false;
              this.initLoadPackage(this.defaultActive);
            }
          });
        } else {
          return false;
        }
      });
    },
    //添加套餐窗口关闭事件
    closeDialogAddPackage() {
      this.$refs["form"].resetFields();
    },
    //打开修改套餐窗口
    openEditPackage(obj) {
      this.editForm = { ...obj };
      this.dialogEditPackage = true;
      var options = [
        { value: 7, label: "七天", disabled: false },
        { value: 30, label: "一个月", disabled: false },
        { value: 183, label: "六个月", disabled: false },
        { value: 365, label: "一年", disabled: false },
        { value: 730, label: "二年", disabled: false },
        { value: 1095, label: "三年", disabled: false },
        { value: 1825, label: "五年", disabled: false }
      ];
      //筛选下拉
      for (let i = 0; i < this.packageList.length; i++) {
        for (let j = 0; j < options.length; j++) {
          if (obj.packageTime != options[j].value) {
            if (this.packageList[i].packageTime == options[j].value) {
              options[j].disabled = true;
            }
          }
        }
      }
      this.optionsTime = options;
    },
    //提交修改套餐
    onSubmitEditPackage() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          editPackageApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.dialogEditPackage = false;
              this.initLoadPackage(this.defaultActive);
            }
          });
        } else {
          return false;
        }
      });
    },
    //修改套餐窗口关闭事件
    closeDialogEditPackage() {
      this.$refs["editForm"].resetFields();
    },
    //删除套餐
    onSubmitDel(obj) {
      this.$confirm("此操作将永久删除该套餐, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delPackageApi({ id: obj.id }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoadPackage(this.defaultActive);
          }
        });
      });
    },

    //套餐组切换
    handleSelect(key) {
      this.initLoadPackage(key);
      this.defaultActive = key;
    },
    //触发焦点记录用户聚焦前的值
    focusEvent(row) {
      row.oldPackageName = row.packageName;
    },
    //失去焦点事件
    blurEvent(row) {
      row.isSelected = !row.isSelected;
      //判断前后两次的值是否一样
      if (row.packageName !== row.oldPackageName) {
        //修改套餐组
        editPackageGroupApi(row).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoadPackageGroup();
          }
        });
      }
    },
    //编辑事件
    cellClick(row) {
      row.isSelected = !row.isSelected;
    },
    //添加套餐组
    onSubmitAddPackageGroup() {
      if (this.packageGroupName == "") {
        this.$message.warning("请填写套餐组名称");
      } else {
        addPackageGroupApi({ packageName: this.packageGroupName }).then(res => {
          if (res) {
            this.packageGroupName = "";
            this.$message.success(res.msg);
            this.initLoadPackageGroup();
            this.dialogAddPackageGroup = false;
          }
        });
      }
    },
    //删除套餐组
    delPackageGroup(obj) {
      this.$confirm("此操作将永久删除该分类和套餐, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delPackageGroupApi({ id: obj.id }).then(res => {
          if (res) {
            if(this.defaultActive==obj.id){
              this.defaultActive=""
            }
            this.$message.success(res.msg);
            this.initLoadPackageGroup();
          }
        });
      });
    }
  }
};
</script>
<style lang='scss'>
.package_title {
  display: flex;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.el-menu--horizontal > .el-menu-item {
  height: 50px;
  line-height: 50px;
}
.el-menu-package {
  border-radius: 4px;
}
.package_btn {
  height: 50px;
  width: 7%;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.package_card {
  position: relative;
  margin-top: 20px;
  cursor: pointer;
}
.package_view {
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #ebeef5;
}
.package_view:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.package_discount {
  color: #ffffff;
  background: #009dff;
  background-image: linear-gradient(90deg, #22d4ff, #009dff);
  position: absolute;
  top: -10px;
  left: 0px;
  border-radius: 6.75px 0 6.75px 0;
  padding: 2px 10px;
  font-size: 12px;
  line-height: 20px;
}
.package_top {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 600;
  height: 50px;
  align-items: center;
}
.package_middle {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  .package_middle_one {
    color: #f60;
  }
  .package_middle_two {
    color: #f60;
    font-size: 32px;
    font-weight: bold;
  }
  .package_middle_three {
    color: rgba(255, 102, 0, 0.35);
    text-decoration: line-through;
    vertical-align: baseline;
    margin-left: 4px;
  }
}
.package_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffe8c0;
  color: #b57d1d;
  font-size: 12px;
  vertical-align: middle;
  border-radius: 0 0 10px 10px;
  padding: 2px;
  .el-button {
    color: #b57d1d;
  }
  .el-button:hover {
    color: #e6a23c;
  }
}
.package_add_view {
  display: flex;
  justify-content: center;
}
.package_add_icon {
  display: flex;
  justify-content: center;
  font-size: 50px;
  color: #8c939d;
  height: 106px;
  line-height: 106px;
}
</style>